$(function () {
    // Indicate the current page in the navigation bar (if a link exists there).
    $(".navigation li a").each(function() {
        if(this.href == window.location) $(this).parents('li').addClass("active");
    });
    $(".breadcrumb li a").each(function() {
        if(this.href == window.location)$(this).parent().addClass("active");
    });

    if(RESPONSIVE) {
        var $slideBars      = new $.slidebars(); // Create new instance of Slidebars
        var $slidebarLeft   = $('.slidebarLeft');
        var $slidebarRight  = $('.slidebarRight');
        var $sbLeft         = $('.sb-left');
        var $sbRight        = $('.sb-right');

        //Open left slidebar
        if($sbLeft.length > 0) {
            $slidebarLeft.on('click', function() {
                $slideBars.slidebars.toggle('left');
            });
        }

        //Open right slidebar
        if($sbRight.length > 0) {
            $slidebarRight.on('click', function() {
                $slideBars.slidebars.toggle('right');
            });

            $sbRight.on('click', '.closeSidebar', function(e) {
                e.preventDefault();
                $slideBars.slidebars.close();
            });

            $sbRight.on('openCart', function() {
                $slideBars.slidebars.open('right');
            });
        }

        //Initialize fastclick to avoid the click delay on touchscreens.
        if($(window).width <= 1024) {
            FastClick.attach(document.body);
        }
    }

    var treeMenu = {
      elm: null,
      initialize: function() {
        this.bindObjects();
        this.generateClasses();
        this.events();
      },
      bindObjects: function() {
        this.elm = $('.treeMenu');
      },
      events: function() {
        //Trigger click on Tree menu "hitarea/.fa"
        $('.fa', this.elm).on('click', this.toggleSubmenuClicked);
      },
      toggleSubmenuClicked: function() {
        $(this).parent('li').toggleClass('open');
      },
      generateClasses: function() {
        //Generates .fa elements as hitareas and class .hasSubmenu
        $('a', this.elm).each(function() {
            var $this = $(this);
            var $li = $this.parent('li');

            if($('ul', $li).length > 0) {
              $li.addClass('hasSubmenu').append('<i class="fa"></i>');
            }
        });
      }
    }.initialize();

    var fixStartpageBoxHeight = function() {
        $('.startpage_boxes .box').css('min-height', 260);
        var height = 0;
        $('.startpage_boxes .box').each(function() {
            var $this = $(this);
            if ($this.height() > height) {
                height = $this.height();
            }
        });
        $('.startpage_boxes .box').css('min-height', height);
    };

    fixStartpageBoxHeight();

    var resizeTimer;
    $(window).on('resize', function(e) {
      fixStartpageBoxHeight();
    });
});
